import React from 'react';
import { Layout } from '../../components/Layout';
import img1 from '../../images/articles/coop.png';
import { Link } from 'gatsby';

import '../../styles/articles-single.css';
import img2 from '../../images/articles/featured-image-gas-co-op-and-utility.jpg';
import avatar from '../../images/user-avatar.png';

const GasCoopUtilityRightOfWaysRural = () => (
  <Layout
    title="Gas CO-OP and Utility Right of Way for Rural Properties"
    description=""
    keywords=""
  >
    <main>
      <div className="article-page-introduction">
        <div className="article-intro-left">
          <div className="article-intro-categories">
            <Link to="/articles/category/utilities-easements">Utilities & Easements</Link>
          </div>

          <h1>Gas CO-OP and Utility Right of Way for Rural Properties</h1>
        </div>

        <div className="article-intro-right">
          <div className="text-center my-4">
            <img src={img2} alt="" width={350} height={250} />
          </div>
        </div>
      </div>

      <p>
        Natural gas infrastructure is a critical aspect of modern living, providing homeowners with
        essential resources to heat their homes and power their appliances. While urban areas have a
        more rigorous and structured approach to installing gas infrastructure, rural areas tend to
        have different systems in place. In rural areas, Gas Co-Ops are a common arrangement for
        natural gas utilities, providing homeowners with access to natural gas utility resources
        while accommodating the nature of rural development.
      </p>

      <p>
        Typically, urban areas use{' '}
        <Link to="/articles/utility-right-of-ways">Utility Right Of Way Plans</Link>, created by an
        Alberta Land Surveyor and registered at the Land Titles Office to install gas
        infrastructure. These are carefully planned out and located to ensure that natural gas
        resources are distributed efficiently and effectively to all the homes. Utility Right of Way
        plans provide the exact location that grants the special rights to access and maintain the
        gas infrastructure. These are registered on a parcel's land title, and the Grantee and
        Grantor have special rights and obligations relating to these right of way locations. These
        locations are also shown on a Real Property Report (RPR) along with any encroachments
        associated with them.
      </p>

      <p>
        In contrast, rural properties typically use Gas Co-Ops, an alternate arrangement for natural
        gas utility installation and management. This less predictable system usually ties new gas
        infrastructure to the nearest existing pipes and as such, they can end up anywhere on any
        given property affected by the Co-Op. There is no Right of Way located for this, and in fact
        it is often the case that the utilities location is ambiguous, though the situation is
        improving in the modern era. This system allows the Gas Co-op operators to install pipes in
        the fastest, cheapest, most convenient way possible. Given the unique low density nature of
        rural development this is quite reasonable, there is little need for additional efforts of
        plan creation and registration when there is so little landowner benefit as long as a
        mechanism exists to protect the infrastructure.
      </p>

      <p>
        A blanket registration can be used to just this effect. Registered on the title, this
        registration encumbers the entire parcel subject to the access and maintenance requirements
        of the Gas Co-Op. It registers the rights of the Co-Op operators to access the entirety of
        the parcel to access and maintain the pipes wherever they might be. An Example of this is
        shown below.
      </p>

      <div className="text-center my-4">
        <img src={img1} alt="Encumbrances, Liens & Interests" width={867} height={330} />
      </div>

      <p>
        If your property has a Gas Co-op registration on it, you should be aware of the rights and
        responsibilities associated with that registration. These details can be accessed by
        obtaining a copy of the registration in its entirety from land titles. While a specific
        location for the Utility Right of Way is not available, it is important to appreciate at
        minimum that the Gas Co-op has legal access to your property and that you would have Gas
        Co-op pipeline infrastructure on the property.
      </p>

      <p>
        From the perspective of Real Property Reports (RPR), the registration will be shown within
        the title block. Because of the previously mentioned blanket registration nature, there is
        no right of way location to identify on the plan portion of the document. The further
        implication of this is that Land Surveyors do not identify the structures on the property as
        encroachments into these types of registrations on a Real Property Report (RPR).
      </p>
    </main>
  </Layout>
);

export default GasCoopUtilityRightOfWaysRural;
